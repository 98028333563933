<template>
  <div class="activity-template" :class="calPaddingBottom" :style="wrapStyle">
    <Tab v-if="showTab" />
    <Rule :show-tab="showTab" v-if="activeTab === 'rule'" :post="handlePost" />
    <Rank v-else-if="activeTab === 'rank'" :post="handlePost" />
    <ContributeList v-else-if="activeTab === 'last'" :post="handlePost" />
    <div class="bottom-btn">
      <a :href="buttonLink"><button @click="handlePost">我要投稿赚金币</button></a>
    </div>
    <IntroModal v-if="showIntro" @close="closeIntro" />
  </div>
</template>

<script>
  import Tab from './components/Tab/index.vue';
  import Rule from './components/Rule/index.vue';
  import Rank from './components/Rank/index.vue';
  import ContributeList from './components/ContributeList/index.vue';
  import IntroModal from '@/components/Modal/IntroModal/index.vue';
  import API from '@/api';
  import { mapGetters } from 'vuex';
  import Download from '@/utils/download';

  export default {
    name: 'ActivityTemplateLanding',
    components: { Tab, Rule, Rank, ContributeList, IntroModal },
    props: {},
    data() {
      return {
        img: require('@/assets/images/common/private_icon.png'),
        buttonInfo: {},
        showIntro: false,
        buttonLink: 'javascript:void(0);',
      };
    },
    computed: {
      ...mapGetters('activity/activityTemplate', ['ActivityInfo', 'activeTab', 'tabMap']),
      calPaddingBottom() {
        if (this.buttonInfo) {
          return 'pb-large';
        } else {
          return 'pb-small';
        }
      },
      showTab() {
        return +this.ActivityInfo.activity_type === 1;
      },
      wrapStyle() {
        const { background_color, background_pic } = this.ActivityInfo;

        return {
          paddingTop: this.showTab ? `1.08rem` : 0,
          paddingBottom: this.showBottomBtn && this.ActivityInfo.activity_type == 1 ? '1.64rem' : 0,
          backgroundColor: background_color,
          backgroundImage: `url(${background_pic})`,
        };
      },
    },
    watch: {},
    async asyncData({ store, $http, params }) {
      const { activity_id: id, device_id, app_name, client_v } = params;
      const data = await $http.post(API.GET_ACTIVITY_PAGE, {
        id,
        device_id,
        app_name,
        client_v,
      });
      store.commit('activity/activityTemplate/SET_ACTIVITY_INFO', data);
      store.commit('activity/activityTemplate/setActivityId', id);
    },
    created() {},
    mounted() {
      if ((this.$platform.Android || this.$platform.iOS) && !this.$platform.WeiXin) {
        const SCHEME =
          this.$route.query.app_name && this.$route.query.app_name !== 'undefined'
            ? this.$route.query.app_name
            : 'kuaiyin';
        let web_url;

        if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development') {
          web_url = `http://h5.rd.kaixinyf.cn/template/activity_template_v2?activity_id=${this.$route.query.activity_id}`;
        } else if (process.env.NODE_ENV === 'pub') {
          web_url = `https://h5.pub.kaixinyf.cn/template/activity_template_v2?activity_id=${this.$route.query.activity_id}`;
        } else if (process.env.NODE_ENV === 'production') {
          web_url = `https://h5.kaixinyf.cn/template/activity_template_v2?activity_id=${this.$route.query.activity_id}`;
        }

        this.buttonLink = `${SCHEME}://web?web_url=${web_url}`;
        console.log(this.buttonLink, 'this.buttonLink--');
      } else {
        this.buttonLink = 'javascript:void(0);';
      }
    },
    methods: {
      handlePost() {
        console.log(`handlePost`);
        try {
          let jump = () => {
            let url;

            if ((this.$platform.Android || this.$platform.iOS) && this.$platform.WeiXin) {
              // url = Download.downLoad('yyb');
              this.showIntro = true;
              document.body.style.overflow = 'hidden';
              return;
            } else if ((this.$platform.Android || this.$platform.iOS) && !this.$platform.WeiXin) {
              url = Download.downLoad('yyb');
              setTimeout(() => {
                window.location.href = url;
              }, 3000);
              return;
            } else if (this.$platform.iOS && !this.$platform.WeiXin) {
              url = Download.downLoad('yyb');
              setTimeout(() => {
                window.location.href = url;
              }, 3000);
              return;
            } else {
              url = Download.downLoad('yyb');
            }
            window.location.href = url;
          };
          jump();
        } catch (e) {
          const { stack } = e;
          this.$logger.error({ error: e, message: stack, stack });
        }
      },
      closeIntro() {
        this.showIntro = false;
        document.body.style.overflow = 'auto';
      },
    },
  };
</script>

<style lang="less" scoped>
  .activity-template {
    position: relative;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    background-color: #f7f7f7;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .pb-large {
    padding-bottom: 0.48rem;
  }
  .pb-small {
    padding-bottom: 0rem;
  }
  .bottom-btn {
    position: fixed;
    bottom: 0.52rem;
    display: flex;
    width: 6.9rem;
    margin-left: 0.3rem;
    justify-content: center;
    button {
      width: 4.6rem;
      height: 0.92rem;
      font-size: 0.28rem;
      font-weight: bold;
      color: #f7f7f7;
      border-radius: 0.46rem;
      border: none;
      background-color: #ff2b3d;
    }
  }
</style>
